import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	OnChanges,
	SimpleChanges,
} from '@angular/core';

import * as Chart from 'chart.js';
import { PluginServiceRegistrationOptions } from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';

@Component({
	selector: 'app-fuel-reduction-skor-charts',
	templateUrl: './fuel-reduction-skor-charts.component.html',
	styleUrls: ['./fuel-reduction-skor-charts.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuelReductionSkorChartComponent implements OnInit, OnDestroy, OnChanges {
	public chart: any;

	@Input() thisFuelChartData: any;
	@Input() prevFuelChartData: any;

	@Input() fuelDailyChartData: any;
	@Input() prevFuelDailyChartData: any;

	constructor(private changeRef: ChangeDetectorRef) {}

	fuelChart: any;

	ngOnInit(): void {
		setTimeout(() => {
			this.createChart();
		}, 1400);
	}

	createChart() {
		if (this.fuelChart) {
			this.fuelChart.destroy();
		}

		const chart = new Chart('fuelSkorChart', {
			plugins: [chartDataLabels],
			type: 'horizontalBar',
			data: {
				labels: ['연비주행', '공회전'],
				datasets: [
					{
						label: '전월',
						data: [...this.prevFuelDailyChartData],
						backgroundColor: '#767171',
						order: 1,
						barPercentage: 0,
						maxBarThickness: 0,
						minBarLength: 1,
						datalabels: {
							display: false, // 연비 로직 완성되면 바인딩 후 true
							align: 'center',
							anchor: 'end',
							offset: -66,

							font: {
								size: 24,
								weight: 'bold',
								lineHeight: 1.8,
							},
							color: '#767171',
							formatter: (value: any, ctx: any) => {
								// const img = new Image();
								// img.src = '../../../assets/renewal/needle/yellow_small.png';

								return '▼';
							},
						},
					},
					{
						label: '당월',
						data: [...this.fuelDailyChartData],
						// backgroundColor: ['#000000', '#ea533c', '#9fa09c'],
						backgroundColor: '#000',
						order: 2,
						// barPercentage: 1,
						maxBarThickness: 20,
						minBarLength: 1,
						datalabels: {
							display: false,
							align: 'top',
							anchor: 'end',
							offset: -12,

							font: {
								size: 20,
								weight: 'bold',
								lineHeight: 1.8,
							},
							color: 'red',
							formatter: (value: any, ctx: any) => {
								// const img = new Image();
								// img.src = '../../../assets/renewal/needle/yellow_small.png';

								return '▼';
							},
						},
					},
				],
			},
			options: {
				responsive: false,
				animation: {
					duration: 0,
				},
				layout: {
					padding: 0,
				},
				legend: {
					display: false,
				},

				tooltips: {
					mode: 'index',
					displayColors: false,
					titleFontSize: 0,
					// callbacks: {
					// 	label: function (ctx: any) {
					// 		console.log(ctx);
					// 		return ctx.value;
					// 	},
					// },
				},
				events: ['click'],

				scales: {
					yAxes: [
						{
							display: false,
							stacked: false,
							gridLines: {
								display: false,
								lineWidth: 0,
								zeroLineWidth: 0,
								drawBorder: false,
							},
							ticks: {
								beginAtZero: true,
							},
						},
					],
					xAxes: [
						{
							ticks: {
								beginAtZero: true,
								min: 0,
								max: 100,
							},
						},
					],
				},
			},
		});

		chart.update();
		this.fuelChart = chart;
		this.changeRef.detectChanges();
	}

	ngOnDestroy(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes) {
			setTimeout(() => {
				this.createChart();
			}, 800);
		}
	}
}
