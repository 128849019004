import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { ChartsModule } from 'ng2-charts';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Daterangepicker } from 'ng2-daterangepicker';
// import { VgCoreModule } from 'videogular2/core';
// import { VgControlsModule } from 'videogular2/controls';
// import { VgOverlayPlayModule } from 'videogular2/overlay-play';
// import { VgBufferingModule } from 'videogular2/buffering';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
// import { VgAPI } from 'videogular2/core';
import { LaddaModule } from 'angular2-ladda';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';

// 📌연비 리포트
import { FeiOverallDonutChartsComponent } from './fei-overall-donut-charts/fei-overall-donut-charts.component';
import { FeiOverallBarChartsComponent } from './fei-overall-bar-charts/fei-overall-bar-charts.component';
import { IdleRatioMultipleChartComponent } from './idle-ratio-multiple-charts/idle-ratio-multiple-charts.component';
import { SuddenAccelMultipleChartComponent } from './sudden-accel-multiple-charts/sudden-accel-multiple-charts.component';
import { SuddenBrakeMultipleChartComponent } from './sudden-brake-multiple-charts/sudden-brake-multiple-charts.component';
import { SuddenStopMultipleChartComponent } from './sudden-stop-multiple-charts/sudden-stop-multiple-charts.component';
import { DailyChangeSpeedLineChartComponent } from './daily-change-speed-line-charts/daily-change-speed-line-charts.component';
import { OccIdleGroupPieChartComponent } from './occurrence-idle-group-pie-charts/occurrence-idle-group-pie-charts.component';
import { IdleRoundBarChartComponent } from './idle-round-bar-charts/idle-round-bar-charts.component';
import { IdleRoundLineChartComponent } from './idle-round-line-charts/idle-round-line-charts.component';
import { IdleNormalBarChartComponent } from './idle-normal-bar-cahrts/idle-normal-bar-cahrts.component';
import { IdleNormalLineChartComponent } from './idle-normal-line-charts/idle-normal-line-charts.component';
import { GarageIdleBarChartComponent } from './garage-idle-bar-charts/garage-idle-bar-charts.component';
import { GarageIdleLineChartComponent } from './garage-idle-line-charts/garage-idle-line-charts.component';
import { FeiCompanyMileageChartComponent } from './fei-company-mileage-charts/fei-company-mileage-charts.component';
import { FeiCompanyTripTimeChartComponent } from './fei-company-triptime-charts/fei-company-triptime-charts.component';
import { FeiCompanyFuelSavingsChartComponent } from './fei-company-fuel-savings-charts/fei-company-fuel-savings-charts.component';
import { FeiCompanyGasSavingsChartComponent } from './fei-company-gas-savings-charts/fei-company-gas-savings-charts.component';
import { FeiCompanyIdlePercentageChartComponent } from './fei-company-idle-percentage-charts/fei-company-idle-percentage-charts.component';
import { StationEntryChartComponent } from './station-entry-charts/station-entry-charts.component';
import { NormalDrivingChartComponent } from './normal-driving-charts/normal-driving-charts.component';
import { ChangeDistributionChartComponent } from './change-distribution-charts/change-distribution-charts.component';
import { IndustryFuelSavingsChartComponent } from './industry-fuel-savings-charts/industry-fuel-savings-charts.component';
import { IndustryGasSavingsChartComponent } from './industry-gas-savings-charts/industry-gas-savings-charts.component';
import { IndustryIdleChartComponent } from './industry-idle-charts/industry-idle-charts.component';
import { IndustryStationEntryChartComponent } from './industry-station-entry-charts/industry-station-entry-charts.component';
import { IndustryNormalDrivingChartComponent } from './industry-normal-driving-charts/industry-normal-driving-charts.component';
import { IndustryDistributionChartComponent } from './industry-distribution-charts/industry-distribution-charts.component';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { BlockUIModule } from 'primeng/blockui';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
// import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
// import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
// import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
// import { GrowlModule } from 'primeng/growl';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { DialogService } from 'primeng/api';

import { AbmModule, AbmComponent } from 'angular-baidu-maps';

import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { AverageComponent } from './average/average.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { VerticalBarChartComponent } from './vertical-bar-chart/vertical-bar-chart.component';
import { VerticalBarRangeChartComponent } from './vertical-bar-range-chart/vertical-bar-range-chart.component';
import { VerticalSliderChartComponent } from './vertical-slider-chart/vertical-slider-chart.component';
import { AverageReportsComponent } from './average-reports/average-reports.component';
import { VerticalBarChartReportsComponent } from './vertical-bar-chart-reports/vertical-bar-chart-reports.component';

import {
	GetDayPipe,
	GetMonthPipe,
	GetYearPipe,
	GetDateEndPipe,
	GetDateStartPipe,
	CustomDateFormatPipe,
	LastUsedPipe,
	DriverDatePipe,
	VideoDatePipe,
} from './pipes/getDayMonthYear.pipe';
import { NumberFormatPipe } from './pipes/numberFormat.pipe';
import { TextPipe, ConvertStringPipe } from './pipes/text.pipe';
import { DatePipePipe } from './pipes/datePipe.pipe';
import { DateRangeComponent } from './date-range/date-range.component';
import { AppDatePickerComponent } from './date-picker/date-picker.component';
import { CustomDatePickerComponent } from './custom-date-picker/custom-date-picker.component';

import { DateRangeCustomComponent } from './date-range-custom/date-range-custom.component';
import { DashboardLoaderComponent } from './dashboard-loader/dashboard-loader.component';

import { SkorViewComponent } from './skor-view/skor-view.component';
import { VideoViewComponent } from './video-view/video-view.component';
import { VideoStatusViewComponent } from './video-status-view/video-status-view.component';
import { VideoPlayPopupComponent } from './video-play-popup/video-play-popup.component';
import { MenuStripComponent } from './menu-strip/menu-strip.component';

import { SkorChartComponent } from './skor-chart/skor-chart.component';
import { SkorTrendLineChartComponent } from './skor-trend-line-chart/skor-trend-line-chart.component';

import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { TrendLineBarChartComponent } from './trend-line-bar-chart/trend-line-bar-chart.component';

import { DriverListTableComponent } from './driver-list/driver-list.component';

import { TripMapComponent } from './trip-map/trip-map.component';

import { TripHistoryPageComponent } from './trip-history-page/trip-history-page.component';
import { TripHistoryTableComponent } from './trip-history-table/trip-history-table.component';
import { TripHistoryTableComponent2 } from './trip-history-table2/trip-history-table.component';
import { TripEventTableComponent } from './trip-event-table/trip-event-table.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { InputBoxComponent } from './input-box/input-box.component';
import { VideoElementComponent } from './video-element/video-element.component';
import { ComboChartComponent } from './combo-chart/combo-chart.component';
import { ComboSeriesVerticalComponent } from './combo-chart/combo-series-vertical.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { DpDatePickerModule } from 'ng2-date-picker';
import { VolumeFormatPipe } from './pipes/VolumeFormat.Pipe';
// import { AgmOverlays } from "agm-overlays"

// renewal report apexCharts, chartjs
import { ApxChartComponent } from './apx-overall-charts/apx-overall-charts.component';
import { ApxMileageChartComponent } from './apx-mileage-charts/apx-mileage-charts.component';
import { ApxTripTimeChartComponent } from './apx-trip-charts/apx-trip-charts.component';
import { ApxDauMileageChartComponent } from './apx-dauMileage-charts/apx-dauMileage-charts.component';
import { MileageLineChartComponent } from './mileage-line-charts/mileage-line-charts.component';
import { DeparturePieChartComponent } from './departure-pie-charts/departure-pie-charts.component';
import { ProtectedDoughnutChartComponent } from './protected-doughnut-charts/protected-doughnut-charts.component';
import { ApxSemiDonutChartComponent } from './apx-semiDonut-charts/apx-semiDonut-charts.component';
import { ApxRadialChartsComponent } from './apx-radial-charts/apx-radial-charts.component';
import { RiskComparisonChartComponent } from './risk-comparison-charts/risk-comparison-charts.component';
import { TurnSignalAppliedComponent } from './turn-signal-charts/turn-signal-charts.component';
import { ImpactBarComponent } from './impact-bar-charts/impact-bar-charts.component';
import { MileageTripChartComponent } from './mileage-trip-charts/mileage-trip-charts.component';
import { LaneBarChartComponent } from './lane-bar-charts/lane-bar-charts.component';
import { TurnOffSignalComponent } from './turn-off-signal-charts/turn-off-signal-charts.component';
import { ForwardCollisionChartComponent } from './forward-collision-charts/forward-collision-charts.component';
import { CollisionDangerChartComponent } from './collision-danger-charts/collision-danger-charts.component';
import { SpeedLimitChartComponent } from './speed-limit-donut-charts/speed-limit-donut-charts.component';
import { ApxHeatmapChartComponent } from './apx-heatmap-charts/apx-heatmap-charts.component';
import { DangerousDrivingCountChartComponent } from './dangerous-driving-count-charts/dangerous-driving-count-charts.component';
import { DangerousDrivingCountStackedComponent } from './dangerous-driving-count-stacked/dangerous-driving-count-stacked.componant';
import { DangerousDrivingHourChartComponent } from './dangerous-driving-hour-charts/dangerous-driving-hour-charts.component';
import { DangerousDrivingGradeStackedComponent } from './dangerous-driving-grade-charts/dangerous-driving-grade-charts.component';
import { DepartureCountChartComponent } from './departure-count-charts/departure-count-charts.component';

// 운전자 상세페이지 관련
import { AverageSkorChartComponent } from './average-skor-charts/average-skor-charts.component';
import { FuelReductionSkorChartComponent } from './fuel-reduction-skor-charts/fuel-reduction-skor-charts.component';
import { AverageSkorTrendChartComponent } from './average-skor-trend-charts/average-skor-trend-charts.component';
import { AverageFeiTrendChartComponent } from './average-fei-trend-charts/average-fei-trend-charts.component';

import { AgmOverlays } from 'agm-overlays';
import { GaugeChartModule } from 'angular-gauge-chart';
import { BrowserModule } from '@angular/platform-browser';
import { NgApexchartsModule } from 'ng-apexcharts';

const PrimengModules = [
	AccordionModule,
	AutoCompleteModule,
	BreadcrumbModule,
	BlockUIModule,
	ButtonModule,
	CalendarModule,
	CardModule,
	CarouselModule,
	// ChartModule,
	CheckboxModule,
	ChipsModule,
	CodeHighlighterModule,
	ConfirmDialogModule,
	ColorPickerModule,
	ContextMenuModule,
	DataViewModule,
	DialogModule,
	DropdownModule,
	DynamicDialogModule,
	// EditorModule,
	FieldsetModule,
	FileUploadModule,
	// FullCalendarModule,
	GalleriaModule,
	// GrowlModule,
	InplaceModule,
	InputMaskModule,
	InputSwitchModule,
	InputTextModule,
	InputTextareaModule,
	LightboxModule,
	ListboxModule,
	MegaMenuModule,
	MenuModule,
	MenubarModule,
	MessageModule,
	MessagesModule,
	MultiSelectModule,
	OrderListModule,
	OrganizationChartModule,
	OverlayPanelModule,
	PaginatorModule,
	PanelModule,
	PanelMenuModule,
	PasswordModule,
	PickListModule,
	ProgressBarModule,
	ProgressSpinnerModule,
	RadioButtonModule,
	RatingModule,
	ScrollPanelModule,
	SelectButtonModule,
	SlideMenuModule,
	SliderModule,
	SpinnerModule,
	SplitButtonModule,
	StepsModule,
	TableModule,
	TabMenuModule,
	TabViewModule,
	TerminalModule,
	TieredMenuModule,
	ToastModule,
	ToggleButtonModule,
	ToolbarModule,
	TooltipModule,
	TreeModule,
	TreeTableModule,
	VirtualScrollerModule,
];

@NgModule({
	imports: [
		NgxChartsModule,
		CommonModule,
		TranslateModule,
		AgmOverlays,
		NgbModule.forRoot(),
		AgmCoreModule.forRoot({
			// apiKey: 'AIzaSyCt5NpXmO4wt6Ufr-RtdOyW0HHt2hjZRqw', // AIzaSyCL8LykrYfie-rTNsi1KJOkF-n-V0yoct0
			apiKey: 'AIzaSyD8kCMdArxocwmXnQwuriC3Jhr7ilO9Hm0', // AIzaSyCL8LykrYfie-rTNsi1KJOkF-n-V0yoct0
			// clientId: 'AIzaSyBZFKKH1OIwdT_lSTBgEDPsZaA1jpm38V0'
			// region: 'JP',
			// language: localStorage.getItem('defaultLng') ? localStorage.getItem('defaultLng') : 'en'
		}),
		AbmModule.forRoot({
			apiKey: 'uBb0dSOvF10tESRVlhvYjhUopYyFUXk2', // app key为必选项
		}),
		AgmDirectionModule,
		HttpClientModule,
		FormsModule,
		ChartsModule,
		Daterangepicker,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		LaddaModule,
		SweetAlert2Module.forRoot(),
		FontAwesomeModule,
		FlexLayoutModule,
		DpDatePickerModule,
		FormsModule,
		ReactiveFormsModule,
		NgApexchartsModule,
		...PrimengModules,
	],
	exports: [
		NgbModule,
		TranslateModule,
		AgmCoreModule,
		VgCoreModule,
		VgControlsModule,
		LaddaModule,
		SweetAlert2Module,
		AgmDirectionModule,
		ChartsModule,
		HttpClientModule,
		FormsModule,
		SpinnerComponent,
		AverageComponent,
		DropdownComponent,
		PieChartComponent,
		SectionHeaderComponent,
		VerticalBarChartComponent,
		VerticalBarRangeChartComponent,
		VerticalSliderChartComponent,
		DateRangeComponent,
		GetDateStartPipe,
		GetDateEndPipe,
		GetDayPipe,
		GetMonthPipe,
		GetYearPipe,
		TextPipe,
		ConvertStringPipe,
		CustomDateFormatPipe,
		LastUsedPipe,
		DriverDatePipe,
		VideoDatePipe,
		NumberFormatPipe,
		DatePipePipe,
		VolumeFormatPipe,
		Daterangepicker,
		AverageReportsComponent,
		VerticalBarChartReportsComponent,
		DashboardLoaderComponent,
		FlexLayoutModule,
		SkorViewComponent,
		VideoViewComponent,
		MenuStripComponent,
		VideoStatusViewComponent,
		VideoPlayPopupComponent,
		...PrimengModules,
		AgmCoreModule,
		AbmComponent,
		SkorChartComponent,
		SkorTrendLineChartComponent,
		DoughnutChartComponent,
		TrendLineBarChartComponent,
		TripHistoryPageComponent,
		TripHistoryTableComponent,
		TripHistoryTableComponent2,
		TripEventTableComponent,
		TripMapComponent,
		DateRangeCustomComponent,
		DriverListTableComponent,
		InputBoxComponent,
		ToggleButtonComponent,
		AppDatePickerComponent,
		CustomDatePickerComponent,
		AlertDialogComponent,
		VideoElementComponent,
		ComboSeriesVerticalComponent,
		ComboChartComponent,
		ApxChartComponent,
		ApxMileageChartComponent,
		ApxTripTimeChartComponent,
		ApxDauMileageChartComponent,
		MileageLineChartComponent,
		DeparturePieChartComponent,
		ProtectedDoughnutChartComponent,
		ApxSemiDonutChartComponent,
		ApxRadialChartsComponent,
		RiskComparisonChartComponent,
		TurnSignalAppliedComponent,
		ImpactBarComponent,
		MileageTripChartComponent,
		LaneBarChartComponent,
		TurnOffSignalComponent,
		ForwardCollisionChartComponent,
		CollisionDangerChartComponent,
		SpeedLimitChartComponent,
		ApxHeatmapChartComponent,
		DangerousDrivingCountChartComponent,
		DangerousDrivingCountStackedComponent,
		DangerousDrivingHourChartComponent,
		DangerousDrivingGradeStackedComponent,
		DepartureCountChartComponent,
		FeiOverallDonutChartsComponent,
		FeiOverallBarChartsComponent,
		IdleRatioMultipleChartComponent,
		SuddenAccelMultipleChartComponent,
		SuddenBrakeMultipleChartComponent,
		SuddenStopMultipleChartComponent,
		DailyChangeSpeedLineChartComponent,
		OccIdleGroupPieChartComponent,
		IdleRoundBarChartComponent,
		IdleRoundLineChartComponent,
		IdleNormalBarChartComponent,
		IdleNormalLineChartComponent,
		GarageIdleBarChartComponent,
		GarageIdleLineChartComponent,
		FeiCompanyMileageChartComponent,
		FeiCompanyTripTimeChartComponent,
		FeiCompanyFuelSavingsChartComponent,
		FeiCompanyGasSavingsChartComponent,
		FeiCompanyIdlePercentageChartComponent,
		StationEntryChartComponent,
		NormalDrivingChartComponent,
		ChangeDistributionChartComponent,
		IndustryFuelSavingsChartComponent,
		IndustryGasSavingsChartComponent,
		IndustryIdleChartComponent,
		IndustryStationEntryChartComponent,
		IndustryNormalDrivingChartComponent,
		IndustryDistributionChartComponent,
		AverageSkorChartComponent,
		FuelReductionSkorChartComponent,
		AverageSkorTrendChartComponent,
		AverageFeiTrendChartComponent,
	],
	declarations: [
		SpinnerComponent,
		AverageComponent,
		DropdownComponent,
		PieChartComponent,
		SectionHeaderComponent,
		VerticalBarChartComponent,
		VerticalBarRangeChartComponent,
		VerticalSliderChartComponent,
		DateRangeComponent,
		GetDateStartPipe,
		GetDateEndPipe,
		GetDayPipe,
		GetMonthPipe,
		GetYearPipe,
		TextPipe,
		ConvertStringPipe,
		NumberFormatPipe,
		VolumeFormatPipe,
		CustomDateFormatPipe,
		LastUsedPipe,
		DriverDatePipe,
		VideoDatePipe,
		DatePipePipe,
		AverageReportsComponent,
		VerticalBarChartReportsComponent,
		DashboardLoaderComponent,

		SkorViewComponent,
		VideoViewComponent,
		MenuStripComponent,
		VideoStatusViewComponent,
		VideoPlayPopupComponent,
		SkorChartComponent,
		SkorTrendLineChartComponent,
		DoughnutChartComponent,
		TrendLineBarChartComponent,
		TripHistoryPageComponent,
		TripHistoryTableComponent,
		TripHistoryTableComponent2,
		TripEventTableComponent,
		TripMapComponent,
		DateRangeCustomComponent,
		DriverListTableComponent,
		InputBoxComponent,
		ToggleButtonComponent,
		AppDatePickerComponent,
		CustomDatePickerComponent,
		AlertDialogComponent,
		VideoElementComponent,
		ComboChartComponent,
		ComboSeriesVerticalComponent,
		ApxChartComponent,
		ApxMileageChartComponent,
		ApxTripTimeChartComponent,
		ApxDauMileageChartComponent,
		MileageLineChartComponent,
		DeparturePieChartComponent,
		ProtectedDoughnutChartComponent,
		ApxSemiDonutChartComponent,
		ApxRadialChartsComponent,
		RiskComparisonChartComponent,
		TurnSignalAppliedComponent,
		ImpactBarComponent,
		MileageTripChartComponent,
		LaneBarChartComponent,
		TurnOffSignalComponent,
		ForwardCollisionChartComponent,
		CollisionDangerChartComponent,
		SpeedLimitChartComponent,
		ApxHeatmapChartComponent,
		DangerousDrivingCountChartComponent,
		DangerousDrivingCountStackedComponent,
		DangerousDrivingHourChartComponent,
		DangerousDrivingGradeStackedComponent,
		DepartureCountChartComponent,
		FeiOverallDonutChartsComponent,
		FeiOverallBarChartsComponent,
		IdleRatioMultipleChartComponent,
		SuddenAccelMultipleChartComponent,
		SuddenBrakeMultipleChartComponent,
		SuddenStopMultipleChartComponent,
		DailyChangeSpeedLineChartComponent,
		OccIdleGroupPieChartComponent,
		IdleRoundBarChartComponent,
		IdleRoundLineChartComponent,
		IdleNormalBarChartComponent,
		IdleNormalLineChartComponent,
		GarageIdleBarChartComponent,
		GarageIdleLineChartComponent,
		FeiCompanyMileageChartComponent,
		FeiCompanyTripTimeChartComponent,
		FeiCompanyFuelSavingsChartComponent,
		FeiCompanyGasSavingsChartComponent,
		FeiCompanyIdlePercentageChartComponent,
		StationEntryChartComponent,
		NormalDrivingChartComponent,
		ChangeDistributionChartComponent,
		IndustryFuelSavingsChartComponent,
		IndustryGasSavingsChartComponent,
		IndustryIdleChartComponent,
		IndustryStationEntryChartComponent,
		IndustryNormalDrivingChartComponent,
		IndustryDistributionChartComponent,
		AverageSkorChartComponent,
		FuelReductionSkorChartComponent,
		AverageSkorTrendChartComponent,
		AverageFeiTrendChartComponent,
	],
	entryComponents: [AlertDialogComponent],
	providers: [],
})
export class SharedModule {}
