import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { DropdownOption } from '../../shared/dropdown/dropdown.component';
import { AnyService } from '../../service/any.service';
import { DOCUMENT } from '@angular/platform-browser';
import { ReportsService } from '../../service/reports.service';
import * as moment from 'moment';
import 'moment/locale/ko';
import { DataService } from '../../service/data.service';
import { DateFormats } from '../../uicommon';
import { AlertService } from '../../service/alert.service';
import { Subject, Subscription, forkJoin, interval } from 'rxjs';

import { startWith, switchMap } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';

import jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import { CustomTranslateService } from '../../service/customTranslate.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SharedService } from 'src/app/service/shared.service';

import dayjs from 'dayjs';
import 'dayjs/locale/ko';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss'],
	// changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		// animation triggers go here
		trigger('openCloseNews', [
			// ...
			state(
				'open',
				style({
					opacity: 1,
					zIndex: 1000,
				})
			),
			state(
				'closed',
				style({
					opacity: 0,
					zIndex: 0,
				})
			),
			transition('open => closed', [animate('0.1s')]),
			transition('closed => open', [animate('0.1s')]),
		]),
		trigger('openCloseAuth', [
			// ...
			state(
				'open',
				style({
					opacity: 1,
					zIndex: 1000,
				})
			),
			state(
				'closed',
				style({
					opacity: 0,
					zIndex: 0,
				})
			),
			transition('open => closed', [animate('0.1s')]),
			transition('closed => open', [animate('0.1s')]),
		]),
	],
})
export class AdminComponent implements OnInit, AfterViewInit {
	@ViewChild('reportBody4') reportBody;
	subs = new Subscription();
	private readonly _stop = new Subject<void>();
	private readonly _start = new Subject<void>();
	DateFormats = DateFormats;
	isAuthenticated = false;
	categoriesForDropdown: DropdownOption[] = [{ title: 'mi' }, { title: 'km' }];
	categoriesForDropdownTheme: DropdownOption[] = [
		{ title: 'Black Theme' },
		{ title: 'White Theme' },
	];
	// 언어 추가 삭제
	Languages = [
		{ id: 'ko', title: 'Korean' },
		// { id: 'en', title: 'English' },
		// { id: 'ja', title: 'Japanese' },
		// { id: 'zh', title: 'Chinese' },
	];
	LanguagesMulti = [
		{ id: 'en', title: 'English' },
		// { id: 'ja', title: 'Japanese' },
		{ id: 'zh', title: 'Chinese' },
		{ id: 'ko', title: 'Korean' },
	];

	datepickerOpen = false;
	pdfcrate = false;
	activeTitle: string;
	activeLanguage: string;
	username: string;
	notiList = [];
	email = '';
	// routersub: any;\
	lang = '';
	eventType = '';
	noticePopup = false;
	noticeData;
	todayOpen = false;
	notiMessage = '';
	notiInfo = { device_id: '', time_stamp: '' };
	noti_count = 0;
	activeTitleTheme = 'White Theme';
	noti_timer;
	noti_news_sw = false;
	noti_authinfo_sw = false;
	subopensw = false;
	currentUrl = '';
	transObj = {};
	imageSrc = '/assets/logo/carvi.png';

	// 📌221206 운행관리 관련 작업, 헤더 카테고리
	drivingSubActive = false;
	drivingSubType = '';

	notiTotalList = [];
	pageLoding = false;
	wideIcon = false;
	// supportFEI = false

	// 📌230111 리뉴얼 리포트 하위 카테고리
	reportSubActive = false;

	// 📌 230515 운행관리 서브메뉴
	isManagement = false;
	isRestMenu = false;

	// 📌 230405 고위험 페이지 오픈 전 crashMonitorService = false
	isSupported = true;
	crashMonitorService = true;
	crashMonitorSub = false;

	roadSubActive = false;
	basicRoadSubActive = false;

	historyManagementSubActive: boolean = false;
	operationManagementSubActive: boolean = false;

	CARVI_COMPANY_ID = 'c5d9c690-eb31-11ea-b98c-6f5c503fb92b';
	DOSI_KWANGJU_COMPANY_ID = '8e9fb20e-5caf-42c3-8f15-09d1348bbf97';
	DOSI_GIMPO_COMPANY_ID = '69aa9256-ef8d-4630-8c96-9a3d2bcc8641';

	support_mq = false;
	menu_en = false;
	support_ws: boolean = false; // 운행관리
	support_ar: boolean = false; // 사고규명
	support_scz: boolean = false; // 스쿨존

	k1_account = false; // k1 계정 판별용

	is_idling_menu = false;

	all_account = false; // 통합, 차고지 계정
	isIndustry = false;

	vehicleList = [];
	activeList = [];

	loading_text = '';

	andong_reports: boolean = false; // 안동시 지역 기초구간 시각화용

	road_data_analysis_company = false;
	rda_company_name = '';

	showDispatchMenu: boolean = false;
	showIssueManagementMenu: boolean = false;
	showDriverManagementMenu: boolean = false;
	showVehicleManagementMenu: boolean = false;
	showNoticeMenu: boolean = false;
	showConsultationMenu: boolean = false;

	qrShow: boolean = false;

	k1AllCompany = false;

	gsDashboard = false;

	constructor(
		public translate: TranslateService,
		private service: AnyService,
		private authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		private reportsService: ReportsService,
		private ds: DataService,
		private anyService: AnyService,
		private alertService: AlertService,
		private customTranslateService: CustomTranslateService,
		private spinner: Ng4LoadingSpinnerService,
		private shared: SharedService,
		@Inject(DOCUMENT) private document: Document
	) {
		this.shared.loadingText$.subscribe((loadingText) => {
			Promise.resolve().then(() => {
				this.loading_text = loadingText;
			});
		});

		if (localStorage.getItem('email') != '') {
			this.email = localStorage.getItem('email');
		}

		const browserLang = translate.getBrowserLang();
	}

	ngOnInit() {
		switch (localStorage.getItem('company_id')) {
			case 'faaad84a-7e12-46ef-bfad-fa8aa5ee6664':
				this.road_data_analysis_company = true;
				this.rda_company_name = '경상북도 안동시';
				break;
			case '9f37160f-1e63-42ae-a862-ef28cae3c736':
				this.road_data_analysis_company = true;
				this.rda_company_name = '(카비)';
				break;
			default:
				this.road_data_analysis_company = false;
				this.rda_company_name = '';
		}

		if (localStorage.getItem('support_scz') === 'true') {
			this.support_scz = true;
		}
		if (localStorage.getItem('industry_id') === '연비절감사업') {
			this.isIndustry = true;
		}

		const carviCompany =
			localStorage.getItem('company_id') === 'a3c8f195-9e6f-4cf0-8275-e2aa6d8979b4' ||
			localStorage.getItem('company_id') === '1b88f34e-1361-45bc-a6ed-c40bfbf1816f';

		if (localStorage.getItem('supportDispatch') === 'true') {
			this.showDispatchMenu = true;
		}

		if (localStorage.getItem('supportIssue') === 'true' || carviCompany) {
			this.showIssueManagementMenu = true;
		}
		if (localStorage.getItem('supportDriver') === 'true' || carviCompany) {
			this.showDriverManagementMenu = true;
		}
		if (localStorage.getItem('supportVehicle') === 'true' || carviCompany) {
			this.showVehicleManagementMenu = true;
		}
		if (localStorage.getItem('supportNotice') === 'true' || carviCompany) {
			this.showNoticeMenu = true;
		}
		if (localStorage.getItem('supportConsultation') === 'true' || carviCompany) {
			this.showConsultationMenu = true;
		}
		if (localStorage.getItem('company_name').includes('케이원')) {
			this.qrShow = true;

			if (localStorage.getItem('company_id') === '9dec70e2-ff0b-4088-b9c5-576f5c2f057e') {
				this.k1AllCompany = true;
			}
		}

		const company_id = localStorage.getItem('company_id');
		const dybus_company_id = 'f4aff4fc-6ab8-47db-a01a-8a1d96559b60';
		const hansungnew_company_id = 'efab651f-8dbe-46be-98af-5a062a155acd';

		if (
			this.isIndustry ||
			localStorage.getItem('company_name').includes('케이원') ||
			company_id === dybus_company_id ||
			company_id === hansungnew_company_id ||
			localStorage.getItem('company_id') === '1b88f34e-1361-45bc-a6ed-c40bfbf1816f' ||
			localStorage.getItem('company_id') === 'a3c8f195-9e6f-4cf0-8275-e2aa6d8979b4' // 카비 내부 개발용
		) {
			this.k1_account = true;
		}

		// this.spinner.show();

		this.anyService.getdatePickerSw().subscribe((value) => {
			this.datepickerOpen = value;
		});
		this.customTranslateService
			.getLang()
			.pipe(
				switchMap((value) => {
					this.lang = value ? value : 'ko';
					if (this.lang == 'en') {
						this.menu_en = true;
					} else {
						this.menu_en = false;
					}
					// console.log('lang', this.lang);
					return this.customTranslateService.sendJson(this.lang);
				})
			)
			.subscribe((value) => {
				const defaultLng = localStorage.getItem('defaultLng');
				this.transObj = value;
				this.noti_list();
				if (!!this.lang && !!defaultLng) {
					if (this.lang !== defaultLng) {
						localStorage.setItem('defaultLng', this.lang);
					}
				}

				this.translate.setDefaultLang(this.lang);
				this.translate.use(this.lang);
				this.pageLoding = true;
				moment.locale(this.lang);
				dayjs.locale(this.lang);
				this.activeLanguage = this.Languages.find((data) => {
					return data.id === this.lang;
				}).title;
				if (localStorage.getItem('email') == 'gp-admin@carvi.co.kr') {
					if (this.lang == 'en') {
						this.username = 'Gimpo';
					} else {
						this.username = '김포도시관리공사';
					}
				}
			});

		this.anyService.getPdfSwTrigger().subscribe((value) => {
			// this.spinner.show();
			if (!!value && value !== 'end') {
				this.pdfcrate = true;
				new Promise((resolve) => {
					setTimeout(() => {
						this.spinner.hide();
						resolve(true);
					}, 2000);
				}).then(() => {
					setTimeout(() => {
						html2canvas
							.default(this.reportBody.nativeElement, { backgroundColor: 'rgba(230,230,226,1)' })
							.then((canvas) => {
								let imgData = canvas.toDataURL('image/jpeg');
								let doc = new jsPDF('p', 'mm', [canvas.width, canvas.height]);
								let width = doc.internal.pageSize.getWidth();
								let height = doc.internal.pageSize.getHeight();
								doc.addImage(imgData, 'jpeg', 0, 0, width, height);
								doc.save(value);
								this.anyService.setPdfSwTrigger('end');
								this.pdfcrate = false;
							});
					}, 100);
				});
			}
		});

		// switch (localStorage.getItem('company_id')) {
		// 	case this.KAKAO_COMPANY_ID:
		// 	case this.KAKAO_KM3_COMPANY_ID:
		// 	case this.KAKAO_KM4_COMPANY_ID:
		// 	case this.KAKAO_KM6_COMPANY_ID:
		// 	case this.KAKAO_ADMIN_COMPANY_ID:
		// 		this.imageSrc = '/assets/logo/kakaomobility.png';
		// 		break;

		// 	default:
		// 		this.imageSrc = '/assets/logo/' + localStorage.getItem('company_id') + '.png';
		// 		break;
		// }

		this.imageSrc = '/assets/logo/' + localStorage.getItem('company_id') + '.png';

		if (
			localStorage.getItem('company_id') !== '9dec70e2-ff0b-4088-b9c5-576f5c2f057e' &&
			localStorage.getItem('garage_id')
		) {
			if (localStorage.getItem('company_name').includes(localStorage.getItem('garage_id'))) {
				this.username = `${localStorage.getItem('DashTitle')}`;
			} else {
				this.username = `${localStorage.getItem('company_name')}(${localStorage.getItem(
					'garage_id'
				)})`;
			}
		} else {
			this.username = localStorage.getItem('company_name');
		}

		// if (localStorage.getItem('company_id') == this.KST_M_COMPANY_ID) this.username = 'Macaron';

		if (localStorage.getItem('email') == 'gp-admin@carvi.co.kr' && this.lang == 'en')
			this.username = 'Gimpo';

		if (localStorage.getItem('theme') == 'white-theme') {
			this.document.body.classList.add('white-theme');
		} else {
			//this.document.body.classList.remove('white-theme');
		}

		if (localStorage.getItem('multi-lang') == 'true') {
			this.Languages = this.LanguagesMulti;
		}

		// 사고 알림 팝업 문구(노선번호, 영업소) 추가하기 위함
		if (localStorage.getItem('company_name').includes('케이원 모빌리티')) {
			this.ds
				.getVehicleList('')
				.then((res) => {
					this.vehicleList = res['vehicle_list'];
					this.activeList = res['vehicle_list'];
					this.vehicleList = this.vehicleList.filter((v) => v.device_id !== v.vehicle_no);
					this.ds.activeDeviceList('dd', '').then((data) => {
						this.activeList.forEach((table: any, index: any) => {
							const isData = data['deviceList'].find((device) => {
								return device['camera_id'] === table['device_id'];
							});

							if (!!isData) {
								this.activeList[index]['activated'] = 'true';
								this.activeList[index]['status'] = isData['status'];
								this.activeList[index]['line_no'] = isData['line_no'];
								this.activeList[index]['garage_loc'] = isData['garage_loc'];
							}
						});
					});

					if (this.activeList && this.vehicleList) {
						this.vehicleList = this.vehicleList.filter((vehicle: any) =>
							this.activeList.some((ele: any) => ele['device_id'] === vehicle['device_id'])
						);

						this.vehicleList.forEach((item: any, key: number) => {
							const findItems = this.activeList.findIndex(
								(data: any, idx: number) => data['device_id'] === item['device_id']
							);

							if (findItems > -1) {
								item['line_no'] = this.activeList[findItems]['line_no'];
								item['garage_loc'] = this.activeList[findItems]['garage_loc'];
							}
						});
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}

	async setBasicRoadData() {
		try {
			const res = await this.ds.getRoadDataVersion(localStorage.getItem('company_id'));
			const versionData = await (res['data'] || res['value']['data']);
			const SKOR_DB_VERSION = versionData.version.skor;
			// const SKOR_DB_VERSION = '20240409';

			await this.ds.getBasicSkorList(SKOR_DB_VERSION);
		} catch (err) {
			console.error(err);
			this.spinner.hide();
			this.shared.setLoadingText('');
		}
	}

	private getChildRouteData(route: ActivatedRoute) {
		if (route.firstChild) {
			return this.getChildRouteData(route.firstChild);
		} else {
			return route.snapshot.data;
		}
	}

	routerLinkWithToken() {
		const userEmail = localStorage.getItem('email');
		const userToken = localStorage.getItem('token');
		const queryParams = `?userEmail=${userEmail}&userToken=${userToken}`;

		if (userEmail && userToken) {
			const url = 'http://carvi-ar.s3-website.ap-northeast-2.amazonaws.com/#/ar' + queryParams;

			window.open(url);
		}
	}

	subSwitch() {
		if (this.router.url.indexOf('event') === -1) {
			this.subopensw = false;
		} else {
			this.subopensw = false;
		}
	}

	idlingSubSwitch() {
		if (this.router.url.indexOf('fei') === -1) {
			this.is_idling_menu = false;
		} else {
			this.is_idling_menu = false;
		}
	}

	reportSubSwitch() {
		if (this.router.url.indexOf('reports') === -1) {
			this.reportSubActive = false;
		} else {
			this.reportSubActive = false;
		}
	}

	historyManagementSubSwitch() {
		if (this.router.url.indexOf('history') === -1) {
			this.historyManagementSubActive = false;
		} else {
			this.historyManagementSubActive = false;
		}
	}

	operationManagementSubSwitch() {
		if (this.router.url.indexOf('operation') === -1) {
			this.operationManagementSubActive = false;
		} else {
			this.operationManagementSubActive = false;
		}
	}

	manageSubSwitch() {
		if (this.router.url.indexOf('manage') === -1) {
			this.isManagement = false;
		} else {
			this.isManagement = false;
		}
	}

	roadSubSwitch() {
		if (this.router.url.indexOf('roadDamage') === -1) {
			this.roadSubActive = false;
		} else {
			this.roadSubActive = false;
		}
	}

	basicRoadSubSwitch() {
		if (this.router.url.indexOf('roadRisk') === -1) {
			this.basicRoadSubActive = false;
		} else {
			this.basicRoadSubActive = false;
		}
	}

	notiswitch(event) {
		this.noti_news_sw = event.target.id !== 'opennews' ? false : true;
		this.noti_authinfo_sw = event.target.id !== 'openauth' ? false : true;
	}
	noti_list() {
		var noti_interval = 30000;
		if (localStorage.getItem('email').indexOf('-admin@') != -1) {
			if (localStorage.getItem('company_id') === 'a3c8f195-9e6f-4cf0-8275-e2aa6d8979b4') {
				// carvidev 카비 내부 개발용 a3c8f195-9e6f-4cf0-8275-e2aa6d8979b4
				console.log('카비 내부 개발 #####');
				noti_interval = 20000;
			} else {
				noti_interval = 300000;
			}
		}
		var event_type = this.road_data_analysis_company
			? ['Crash Detected', 'Pothole Detected']
			: ['Crash Detected'];

		if (localStorage.getItem('support_mq') == 'true') {
			this.support_mq = true;
			this.crashMonitorSub = true;
			if (localStorage.getItem('company_id') != 'd6f3d3d3-b046-4047-bad2-2576b5c84701') {
				event_type.push('Smoke', 'Drunk Driving');
			}
		}

		if (localStorage.getItem('support_ws') === 'true') {
			this.support_ws = true;
		}

		if (localStorage.getItem('support_ar') === 'true') {
			this.support_ar = true;
		}

		this.subs.add(
			interval(noti_interval)
				.pipe(
					startWith(0),
					switchMap(() => forkJoin(this.ds.getNotiList(event_type, 'true')))
				)
				.subscribe(([events]) => {
					let list = events.recentList;

					// switch (localStorage.getItem('email')) {
					// 	case 'koroad-gimpo@carvi.co.kr':
					// 		list = list.filter((v) => v.vehicle_no.indexOf('김포') != -1);
					// 		break;
					// 	case 'koroad-gwangju@carvi.co.kr':
					// 		list = list.filter((v) => v.vehicle_no.indexOf('광주') != -1);
					// 		break;
					// 	case 'koroad-daegu@carvi.co.kr':
					// 		list = list.filter((v) => v.vehicle_no.indexOf('대구') != -1);
					// 		break;
					// 	case 'koroad-changwon@carvi.co.kr':
					// 		list = list.filter((v) => v.vehicle_no.indexOf('창원') != -1);
					// 		break;
					// }

					if (Object.keys(this.transObj).length > 0) {
						list.map((t) => {
							t.event =
								t.event === 'Smoke'
									? this.transObj['EVENT']['Smoke Detected']
									: t.event === 'Crash Detected'
									? this.transObj['RISK']['Crash Detected']
									: t.event === 'Drunk Driving'
									? this.transObj['EVENT']['Drunk Driving']
									: t.event;
						});
					}

					list.sort((a, b) => {
						return +(a.time_stamp < b.time_stamp) || +(a.time_stamp === b.time_stamp) - 1;
					});
					let company_data = [];
					const isSubCompany = localStorage.getItem('sub_company_id_length');
					if (!!isSubCompany) {
						for (let index = 0; index < +isSubCompany; index++) {
							company_data = company_data.concat(
								list.filter((value) => {
									return value['company_id'] === localStorage.getItem('sub_company_id' + index);
								})
							);
						}
					} else {
						company_data = list.filter((value) => {
							return value['company_id'] === localStorage.getItem('company_id');
						});
					}

					company_data.forEach((item: any, key: number) => {
						const findIdx = this.vehicleList.findIndex(
							(ele: any) => ele['device_id'] === item['device_id']
						);

						if (findIdx > -1) {
							item['line_no'] = this.vehicleList[findIdx]['line_no'];
							item['garage_loc'] = this.vehicleList[findIdx]['garage_loc'];
						}
					});

					let notiList = [];
					if (company_data.length > 0) {
						company_data.sort((a, b) => {
							return +(a.time_stamp < b.time_stamp) || +(a.time_stamp === b.time_stamp) - 1;
						});
						notiList = company_data.slice(0, 10);

						if (this.notiList.length === 0) {
							this.notiList = notiList;
						} else {
							const originalList = JSON.stringify(this.notiList);
							const newList = JSON.stringify(notiList);
							if (originalList !== newList) {
								this.notiList = notiList;
							}
						}

						this.notiList.forEach((item: any) => {
							const andongbus_id = '3bcc7fcc-0760-4ab2-82c2-baff9509c887';
							const dongchun_id = '50a943e6-02e6-41b8-8462-a9d9e63cdcd3';
							const kyungan_id = 'd041723b-3662-4a61-84d3-e628eb1f5b73';
							const andongsisul_id = '08624feb-8676-4bc6-9cb5-8d46e78c1d59';

							if (item['company_id'] === andongbus_id) {
								item['company_name'] = '안동버스';
							} else if (item['company_id'] === dongchun_id) {
								item['company_name'] = '동춘여객';
							} else if (item['company_id'] === kyungan_id) {
								item['company_name'] = '경안여객';
							} else if (item['company_id'] === andongsisul_id) {
								item['company_name'] = '안동시시설관리공단';
							} else {
								item['company_name'] = '-';
							}

							if (localStorage.getItem('DashTitle').includes('케이원')) {
								if (item['driver_name_matched']) {
									const name = item['driver_name_matched'].split('_');
									item['driver_name_matched'] =
										name.length > 1 ? name[1] : item['driver_name_matched'];
								} else if (item['driver_name_guessed']) {
									const name = item['driver_name_guessed'].split('_');
									item['driver_name_guessed'] =
										name.length > 1 ? name[1] : item['driver_name_guessed'];
								}
							}
						});
						const noConfrim = notiList.find((data) => {
							return (
								data['noti_confirm'] === 'false' ||
								data['noti_confirm'] === null ||
								data['noti_confirm'] === undefined
							);
						});

						if (!!noConfrim) {
						}

						if (!this.noticeData && !!noConfrim) {
							if (noConfrim['line_no'] || noConfrim['garage_loc']) {
								console.log(noConfrim);
								this.notiMessage =
									(noConfrim['event'] === 'Pothole Detected' ? '포트홀' : noConfrim['event']) +
									': ' +
									(noConfrim['line_no'] ? noConfrim['line_no'] + '/' : '') +
									(noConfrim['garage_loc'] ? noConfrim['garage_loc'] + '/' : '') +
									(noConfrim['driver_name'] ? noConfrim['driver_name'] : noConfrim['driver_id']);
							} else {
								this.notiMessage =
									(noConfrim['event'] === 'Pothole Detected' ? '포트홀' : noConfrim['event']) +
									': ' +
									(noConfrim['driver_name'] ? noConfrim['driver_name'] : noConfrim['driver_id']);
							}

							this.notiInfo = {
								device_id: noConfrim['device_id'],
								time_stamp: noConfrim['time_stamp'],
							};
							this.noti_count++;
							if (this.road_data_analysis_company && noConfrim['event'] === 'Pothole Detected') {
								this.noticePopup = false;
							} else {
								this.noticePopup = true;
							}
						} else {
							if (!!noConfrim) {
								console.log(noConfrim);
								if (
									this.noticeData['device_id'] !== noConfrim['device_id'] &&
									this.noticeData['time_stamp'] !== noConfrim['time_stamp']
								) {
									if (noConfrim['line_no'] || noConfrim['garage_loc']) {
										this.notiMessage =
											(noConfrim['event'] === 'Pothole Detected' ? '포트홀' : noConfrim['event']) +
											': ' +
											(noConfrim['line_no'] ? noConfrim['line_no'] + '/' : '') +
											(noConfrim['garage_loc'] ? noConfrim['garage_loc'] + '/' : '') +
											(noConfrim['driver_name']
												? noConfrim['driver_name']
												: noConfrim['driver_id']);
									} else {
										this.notiMessage =
											(noConfrim['event'] === 'Pothole Detected' ? '포트홀' : noConfrim['event']) +
											': ' +
											(noConfrim['driver_name']
												? noConfrim['driver_name']
												: noConfrim['driver_id']);
									}

									this.notiInfo = {
										device_id: noConfrim['device_id'],
										time_stamp: noConfrim['time_stamp'],
									};
									this.noti_count++;
									this.noticeData = noConfrim;
									if (
										this.road_data_analysis_company &&
										noConfrim['event'] === 'Pothole Detected'
									) {
										this.noticePopup = false;
									} else {
										this.noticePopup = true;
									}
								} else {
									if (!noConfrim['noti_confirm']) {
										if (noConfrim['line_no'] || noConfrim['garage_loc']) {
											this.notiMessage =
												(noConfrim['event'] === 'Pothole Detected'
													? '포트홀'
													: noConfrim['event']) +
												': ' +
												(noConfrim['line_no'] ? noConfrim['line_no'] + '/' : '') +
												(noConfrim['garage_loc'] ? noConfrim['garage_loc'] + '/' : '') +
												(noConfrim['driver_name']
													? noConfrim['driver_name']
													: noConfrim['driver_id']);
										} else {
											this.notiMessage =
												(noConfrim['event'] === 'Pothole Detected'
													? '포트홀'
													: noConfrim['event']) +
												': ' +
												(noConfrim['driver_name']
													? noConfrim['driver_name']
													: noConfrim['driver_id']);
										}

										this.notiInfo = {
											device_id: noConfrim['device_id'],
											time_stamp: noConfrim['time_stamp'],
										};
										this.noti_count++;
										this.noticeData = noConfrim;
										if (
											this.road_data_analysis_company &&
											noConfrim['event'] === 'Pothole Detected'
										) {
											this.noticePopup = false;
										} else {
											this.noticePopup = true;
										}
									}
								}
							}
						}

						// 📌221205 헤더 알림보드, 종류: 사고 발생 -> 고 위험 충격
						if (notiList && notiList.length > 0) {
							notiList.forEach((item: any) => {
								if (item.event === '사고 발생') {
									item.event = '교통사고';
								}
							});
						}
					}
				})
		);
	}
	selectedItemTheme(item: DropdownOption) {
		this.activeTitleTheme = item.title;
	}
	notiConfirm(notiInfo) {
		console.log(notiInfo);
		console.log(this.notiList);
		this.noticePopup = false;
		this.noticeData = {
			device_id: notiInfo.device_id,
			time_stamp: notiInfo.time_stamp,
		};
		this.noti_count = 0;
		const index = this.notiList.findIndex((value) => {
			return (
				this.noticeData['device_id'] === value['device_id'] &&
				this.noticeData['time_stamp'] === value['time_stamp']
			);
		});
		if (index > -1) {
			this.notiList[index]['noti_confirm'] = 'true';
		}
		this.ds.notiConfirm(notiInfo).then((res) => {
			console.log(res);
		});
	}
	closePop() {
		this.noticePopup = false;
	}
	changeLanguage(lang, selectedLangTitle: string) {
		this.customTranslateService.setLang(lang);
		this.spinner.hide();
	}

	selectedItem(item: DropdownOption) {
		this.activeTitle = item.title;
		this.service.setCurrentUnit(item.title);
	}

	onDownloadReport() {
		this.reportsService.loadReport.next(true);
	}

	goHome() {
		if (localStorage.getItem('group_id') !== 'true') {
			const groupCompanyId = localStorage.getItem('group_company_id'); // 통합 id
			const groupCompanyEmail = localStorage.getItem('group_company_email');
			const loginType = localStorage.getItem('all_login_status'); // 1통합, 2운수사
			const garageLogin = localStorage.getItem('first_garage_login');

			if (localStorage.getItem('company_id') !== groupCompanyId) {
				if (loginType === '1') {
					this.setCompanyData(groupCompanyEmail);
				} else {
					const companyName = localStorage.getItem('company_name');
					localStorage.setItem('DashTitle', companyName);
					localStorage.setItem('company_name', companyName);
				}

				if (this.route.snapshot['_routerState'].url.indexOf('overview') > -1) {
					if (localStorage.getItem('garage_id')) {
						window.location.reload();
					} else {
						if (localStorage.getItem('company_id') === groupCompanyId) {
							window.location.reload();
						} else {
							this.router.navigate(['/overview']);
						}
					}
				} else {
					this.router.navigate(['/overview']);
				}

				this.username = localStorage.getItem('company_name');

				if (!garageLogin) {
					localStorage.removeItem('garage_id');
				}
			} else {
				this.router.navigate(['/overview']);
			}
		} else {
			this.router.navigate(['/overview']);
		}
	}

	setCompanyData(email: string) {
		this.authService.signInFleetadmin(email, '').subscribe((data) => {
			localStorage.setItem('email', email);

			if (data['data'].company_id == 'b12ed020-2897-11eb-a5fe-bfbd1648d326') {
				localStorage.setItem('company_name', 'Macaron Taxi by CARVI');
			} else {
				localStorage.setItem('company_name', data['data'].company_name);
			}

			if (data['data']['company_status'] === '2') {
				const list: object[] = [...data['data']['child_company_id']];
				list.forEach((value, index) => {
					localStorage.setItem('sub_company_id' + index, String(value));
				});
				localStorage.setItem('sub_company_id_length', String(list.length));

				if (data['data']['garage_id']) {
					localStorage.setItem('first_garage_login', 'true');
				}
			}

			localStorage.setItem('group_id', data['data'].group_id);
			localStorage.setItem('company_id', data['data'].company_id);

			if (localStorage.getItem('group_company_id') === data['data'].company_id) {
				localStorage.removeItem('garage_id');
				localStorage.removeItem('supportDispatch');
				localStorage.removeItem('supportIssue');
				localStorage.removeItem('supportDriver');
				localStorage.removeItem('supportVehicle');
			}

			// localStorage.removeItem('supportNotice');
			// localStorage.removeItem('supportConsultation');

			localStorage.setItem('DashTitle', data['data'].dash_title);
			localStorage.setItem('company_type', data['data'].company_type);
			localStorage.setItem('fei', data['data'].fei);
			localStorage.setItem('show_driver', data['data'].show_driver);

			if (data['data'].location_list) {
				localStorage.setItem('location_list', JSON.stringify(data['data'].location_list));
			}
			if (data['data'].support_mq) {
				localStorage.setItem('support_mq', data['data'].support_mq);
			}
			if (data['data'].support_ws) {
				localStorage.setItem('support_ws', data['data'].support_ws);
			}
			if (data['data'].show_region_info) {
				localStorage.setItem('show_region_info', data['data'].show_region_info);
			}
			if (data['data'].company_vehicles) {
				localStorage.setItem('company_vehicles', data['data'].company_vehicles);
			}
			if (data['data'].audio_in) {
				localStorage.setItem('audio_in', data['data'].audio_in);
			}
			if (data['data'].industry_id) {
				localStorage.setItem('industry_id', data['data'].industry_id);
			}
			if (data['data'].report_name) {
				localStorage.setItem('report_name', data['data'].report_name);
			}
			if (data['data'].hide_driver_rank) {
				localStorage.setItem('hide_driver_rank', data['data'].hide_driver_rank);
			}
			if (data['data'].support_fei_report) {
				localStorage.setItem('support_fei_report', data['data'].support_fei_report);
			}
			if (data['data'].support_ar) {
				localStorage.setItem('support_ar', data['data'].support_ar);
			}
			if (data['data'].idling_limit) {
				localStorage.setItem('idling_limit', data['data'].idling_limit);
			}

			if (data['data'].group_id && data['data'].garage_info) {
				localStorage.setItem('garage_info', JSON.stringify(data['data'].garage_info));
			}

			window.location.reload();
		});
	}

	goMypage() {
		this.router.navigate(['/mypage/myaccount']);
	}
	onLogout() {
		this.authService.logout();
	}
	goEvent(event) {
		if (event === this.transObj['RISK']['High Risk Shock']) {
			this.router.navigate(['/event/crash']);
		} else if (event === this.transObj['RISK']['Smoke Detected']) {
			this.router.navigate(['/event/smoke']);
		} else if (event === this.transObj['RISK']['Drunk Driving']) {
			this.router.navigate(['/event/drunk']);
		} else if (event === this.transObj['RISK']['Crash Monitor']) {
			this.router.navigate(['/event/monitor']);
		} else if (event === 'Pothole Detected') {
			this.router.navigate(['/roadDamage']);
		}
	}
	goManage(type: string) {
		console.log(type);
		if (type === 'records') {
			this.router.navigate(['/manage/records']);
		} else if (type === 'rest') {
			this.router.navigate(['/manage/rest']);
		}
	}

	not_supported_alert() {
		this.alertService.alertDialog(
			this.transObj['ALERTS'][
				'This service is not supported by your subscription plan. Please contact the provider.'
			]
		);
	}

	not_supported_service() {
		this.alertService
			.alertDialog(this.transObj['ALERTS']['This service is not currently supported'])
			.afterClosed();
	}

	notService() {
		this.alertService
			.alertDialog(this.transObj['ALERTS']['This service is not currently supported'])
			.afterClosed();
	}

	public handleMissingImage(event: Event) {
		this.imageSrc = '/assets/logo/carvi.png';
	}

	feiSubActiveMenu = false;
	eventSubActiveMenu = false;
	reportSubActiveMenu = false;
	manageSubActiveMenu = false;
	historySubActiveMenu = false;
	operationSubActiveMenu = false;
	roadSubActiveMenu = false;
	basicRoadSubActiveMenu = false;

	ngAfterViewInit() {
		// this.spinner.hide();

		this.router.events.subscribe(() => {
			this.feiSubActiveMenu = ['/idling', '/fei'].includes(this.router.url);
			this.manageSubActiveMenu = ['/qr', '/manage'].includes(this.router.url);
			this.roadSubActiveMenu = ['/roadDamage', '/roadManage'].includes(this.router.url);
			this.basicRoadSubActiveMenu = ['/basicRoad', '/roadRisk'].includes(this.router.url);

			this.eventSubActiveMenu = this.router.url.includes('/event');
			this.reportSubActiveMenu = this.router.url.includes('/report');
			this.historySubActiveMenu = this.router.url.includes('/history');
			this.operationSubActiveMenu = this.router.url.includes('/operation');
		});
	}

	ngOnDestroy() {
		clearInterval(this.noti_timer);
		this.subs.unsubscribe();
	}
}
