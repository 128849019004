import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
	DateRange,
	Driver2,
	DriverStats2,
	Trip,
	VideoEntry,
	CameraIdHolder,
} from './types/data-types';
import { DataService } from './service/data.service';
import { driver2TempName } from './types/common';
import { AuthService } from './service/auth.service';
import * as moment from 'moment';
import { RealtimeAppsyncService } from './realtime-appsync.service';
import { REAL_TIME_DATA_QUERY } from './queries/queries';

export const CustomRangeLabel = -1;

interface Driver2Bundle extends Driver2 {
	sub?: ZenObservable.Subscription;
}

@Injectable({
	providedIn: 'root',
})
export class AppContextService {
	// 외부에서 변경.
	dateRangeSelected$ = new BehaviorSubject<DateRange>({
		start: moment().subtract(30, 'days').toDate(),
		end: moment().add(10, 'minutes').toDate(),
	});
	dateLabelSelected$ = new BehaviorSubject<number>(30); // -1이면 custom 범위.
	tripSelected$ = new BehaviorSubject<Trip>(undefined);
	driverSelected$ = new BehaviorSubject<Driver2>(undefined);

	// 다른 subject에 따라 바뀜. 외부에서는 수신만.
	driverList$ = new BehaviorSubject<Driver2Bundle[]>([]);
	driverListLoading$ = new BehaviorSubject<boolean>(false);
	driver_performence$ = new BehaviorSubject<boolean>(false);
	companyStats$ = new BehaviorSubject<DriverStats2>(undefined);
	trips$ = new BehaviorSubject<Trip[]>(undefined);
	tripsLoading$ = new BehaviorSubject<boolean>(false);
	videoList$ = new BehaviorSubject<VideoEntry[]>([]);
	videoListLoading$ = new BehaviorSubject<boolean>(false);
	eventsLoading$ = new BehaviorSubject<boolean>(false);

	constructor(
		private dataService: DataService,
		private authService: AuthService,
		private rtSync: RealtimeAppsyncService
	) {
		this.authService.isLoggedIn$.subscribe({
			next: (loggedIn) => {
				if (!loggedIn) {
					this.driverSelected$.next(undefined);
					this.tripSelected$.next(undefined);
					this.trips$.next(undefined);
					this.companyStats$.next(undefined);
					this.setDriverList([]);
				} else {
					this.init();
				}
			},
		});
	}

	init() {
		this.dateRangeSelected$.subscribe({
			next: this.onDateRangeSelected.bind(this),
		});
		this.driverSelected$.subscribe({
			next: this.onDriverSelected.bind(this),
		});
		this.trips$.subscribe({
			next: (trips) => {
				console.log(trips);
			},
		});
	}

	setDateRangeByDays(days: number) {
		//
		if (days == this.dateLabelSelected$.value) {
			return;
		}

		this.dateLabelSelected$.next(days);
		this.dateRangeSelected$.next({
			start: moment()
				.subtract(days, 'days')
				.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
				.toDate(),
			end: moment().add(10, 'minutes').toDate(),
		});
	}

	async onDateRangeSelected(dateRange: { start: string; end: string }) {
		this.driverListLoading$.next(true);
		const getStartDate = moment(dateRange.start).format('YYYY-MM-DD 00:00:00');
		const getEndDate = moment(dateRange.end).format('YYYY-MM-DD 23:59:59');
		const [driverStats, companyStats] = await Promise.all([
			this.dataService.getDriversList(getStartDate, getEndDate),
			this.dataService.getCompanyStats(getStartDate, getEndDate),
		]);
		this.companyStats$.next(companyStats.stats);
		console.log('driverStats');
		console.log(driverStats);
		const drivers = [];
		this.setDriverList(drivers);
		console.log(drivers);
		this.driverListLoading$.next(false);
		if (drivers.length > 0) {
			this.driverSelected$.next(drivers[0]);
		} else {
			this.driverSelected$.next(undefined);
		}
	}

	setDriverList(drivers: Driver2[]) {
		this.driverList$.value.forEach((d) => {
			d.sub.unsubscribe();
		});

		const driversBundle = drivers as Driver2Bundle[];
		driversBundle.forEach(async (d) => {
			// #HACK
			d.name = driver2TempName(d.camera_id);

			// realtime 상태 싱크
			const client = await this.rtSync.client.hydrated();
			const observable = client.subscribe({
				query: REAL_TIME_DATA_QUERY,
				variables: { CameraId: d.camera_id },
			});
			d.sub = observable.subscribe({
				next: (data: { data: { onUpdateRealtimeData: CameraIdHolder } }) => {
					d.realTime = true;
				},
			});
		});

		this.driverList$.next(drivers);
	}

	async onDriverSelected(driver: Driver2) {
		if (driver) {
		}
	}

	async loadTripEvents(trip: Trip) {
		// if(start && end ) {
		//     trip.trip_start = start
		//     trip.trip_end = end
		// }
		this.eventsLoading$.next(true);
		const events = await this.dataService.tripapi(trip.device_id, trip.trip_start, trip.trip_end);
		this.eventsLoading$.next(false);

		// 자기가 요청한 비디오만 보여주자. (일반 계정)
		let email = localStorage.getItem('email');
		if (email.indexOf('-admin@') == -1) {
			events.forEach((item) => {
				if (item['video_status'] == 4 && item['requester'] && item['requester'] != email) {
					item['video_status'] = 1;
				}
			});
		}
		return events;
	}
}
