import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ElementRef,
	ViewChild,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
} from '@angular/core';
import { VideoEntryBundle } from '../../types/data-types';
import { DataService } from '../../service/data.service';
import { TripsService } from '../../service/trips.service';
import { AlertService } from 'src/app/service/alert.service';

@Component({
	selector: 'app-video-element',
	templateUrl: './video-element.component.html',
	styleUrls: ['./video-element.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoElementComponent implements OnInit {
	@Input() video: VideoEntryBundle;
	@Input('index') index: number;
	@Output() loaded = new EventEmitter();
	@ViewChild('media') videoEl: ElementRef;
	@Input() showing_number: number;

	playPopup = false;
	isPlaying = false;
	isLoading = false;
	errorCheck = false;

	constructor(
		public ds: DataService,
		private tripsService: TripsService,
		private alertService: AlertService,
		private changeRef: ChangeDetectorRef
	) {}

	ngOnInit() {
		const garageId = localStorage.getItem('garage_id');
		const companyId = localStorage.getItem('company_id');
		const specificCompanyIds = [
			'9dec70e2-ff0b-4088-b9c5-576f5c2f057e',
			'f5ee1c83-b0c0-4192-8352-a7388f67fd46',
		];

		if (garageId || specificCompanyIds.includes(companyId)) {
			if (this.index <= this.showing_number + 12) {
				this.videoThumbnail();
			}
		} else {
			this.videoThumbnail();
		}
	}

	videoThumbnail() {
		let url = this.video['video_file'].replace('/mnt/mmc/', '');
		let urlComps = url.split('/');
		let deviceId = this.video['old_device_id'] || this.video['device_id'];
		let thumbnail = `http://carvi-static-image.s3-website.ap-northeast-2.amazonaws.com/${deviceId}/event/${urlComps[2]}`;
		thumbnail = thumbnail.replace(/\.\w+$/, `-thumbnail.jpg`);

		let tester = new Image();
		tester.onerror = () => {
			this.errorCheck = true;
			// 2022.09.08 썸네일 대체 이미지
			// this.video['thumbnail'] = '/assets/icon-standard.png'
		};

		tester.src = thumbnail;
		this.video['thumbnail'] = thumbnail;
	}

	imageFound(tester) {
		console.log(tester + 'imageFound');
	}

	imageNotFound(tester) {
		console.log(tester + 'imageNotFound');
	}

	// 영상 썸네일 클릭시 실행
	playVideo() {
		if (!this.video.url) {
			this.loadVideoUrl();
		} else {
			this.toggleVideoPlayback();
		}

		this.changeRef.markForCheck();
	}

	private loadVideoUrl() {
		this.isLoading = true;
		this.ds
			.getCameraStatusByID(this.video)
			.then((res) => {
				if (res.status === 'success') {
					this.video.url = res.message.video_url;
					this.initializeVideoPlayer();
				} else {
					this.handleVideoError();
				}
			})
			.catch((err) => {
				console.error(err);
				this.isLoading = false;
			});
	}

	private initializeVideoPlayer() {
		const videoElement = this.videoEl.nativeElement;
		videoElement.load();
		this.loaded.emit(this.video);

		videoElement.onloadeddata = () => {
			this.isLoading = false;
			videoElement.play();
			this.isPlaying = true;
		};

		videoElement.onended = () => {
			videoElement.pause();
			this.isPlaying = false;
		};
	}

	private toggleVideoPlayback() {
		const videoElement = this.videoEl.nativeElement;
		if (videoElement.paused) {
			videoElement.play();
			this.isPlaying = true;
		} else {
			videoElement.pause();
			this.isPlaying = false;
		}
	}

	private handleVideoError() {
		this.isLoading = false;
		this.isPlaying = false;
		this.alertService.alertDialog('영상 파일이 존재하지 않습니다.<br/>관리자에게 문의해 주세요.');
	}

	toggleDetail(video: any, type: boolean) {
		this.ds
			.getCameraStatusByID(video)
			.then((res) => {
				if (res.status === 'success') {
					this.playPopup = type;
					this.tripsService.setTripVideoTrigger2(this.video);
					this.changeRef.markForCheck();
				} else {
					this.alertService.alertDialog(
						'영상 파일이 존재하지 않습니다.<br/>관리자에게 문의해 주세요.'
					);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}
}
